module.exports = {
    BNB: {
        /* WBNB */
        WETH: {
            address: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
            decimals: 18
        },
        /* BUSD */
        STABLE: {
            address: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
            decimals: 18
        }
    },
    ETH: {
        WETH: {
            address: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
            decimals: 18
        },
        /* USDC */
        STABLE: {
            address: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
            decimals: 18
        }
    },
    MATIC: {
        WETH: {
            address: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
            decimals: 18
        },
        /* USDC */
        STABLE: {
            address: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
            decimals: 18
        }
    },
    GOERLI: {
        WETH: {
            address: '0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6',
            decimals: 18
        },
        /* USDC */
        STABLE: {
            address: '0x2f3A40A3db8a7e3D09B0adfEfbCe4f6F81927557',
            decimals: 18
        }
    },
    TESTBNB: {
        WETH: {
            address: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
            decimals: 18
        },
        /* USDC */
        STABLE: {
            address: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
            decimals: 18
        }
    },
}
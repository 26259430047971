module.exports = {
    BNB: [
        { value: '0x001e32d2E36422bAC1a4912a25E85843BaD1c0bd', label: 'BFR' },
        { value: '0x0000000000000000000000000000000000000000', label: 'BNB' },
        { value: '0x55d398326f99059ff775485246999027b3197955', label: 'USDT' },
        { value: '0xe9e7cea3dedca5984780bafc599bd69add087d56', label: 'BUSD' },
        { value: '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c', label: 'BTC' },
        { value: '0x2170ed0880ac9a755fd29b2688956bd959f933f8', label: 'ETH' },
        { value: '0x3d6545b08693dae087e957cb1180ee38b9e3c25e', label: 'ETC' },
        { value: '0x4338665cbb7b2485a8855a139b75d5e34ab0db94', label: 'LTC' },
        { value: '0x1d2f0da169ceb9fc7b3144628db156f3f6c60dbe', label: 'XRP' },
        { value: '0x3ee2200efb3400fabb9aacf31297cbdd1d435d47', label: 'ADA' },
        { value: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82', label: 'CAKE' },
        { value: '0x111111111117dc0aa78b770fa6a738034120c302', label: '1INCH' },
        { value: '0xba2ae424d960c26247dd6c32edc70b295c744c43', label: 'DOGE' },
        { value: '0x7083609fce4d1d8dc0c979aab8c869ea2c873402', label: 'DOT' },
        { value: '0x8ff795a6f4d97e7887c79bea79aba5cc76444adf', label: 'BCH' },
        { value: '0xf8a0bf9cf54bb92f17374d9e9a321e6a111a51bd', label: 'LINK' },
        { value: '0xcc42724c6683b7e57334c4e856f4c9965ed682bd', label: 'POLYGON'},
        { value: '0x85eac5ac2f758618dfa09bdbe0cf174e7d574d5b', label: 'TRX' },
        { value: '0xfb6115445bff7b52feb98650c87f44907e58f802', label: 'AAVE' },
        { value: '0x0eb3a705fc54725037cc9e008bdede697f62f335', label: 'ATOM' },
        { value: '0x16939ef78684453bfdfb47825f8a5f714f12623a', label: 'XTZ' },
        { value: '0x52ce071bd9b1c4b00a0b92d298c512478cad67e8', label: 'COMP' },
        
    ],
    ETH: [
        { value: '0x2722365d51563104beb193e648f799d6fd038b50', label: 'BFR' },
        { value: '0x0000000000000000000000000000000000000000', label: 'ETH' },
      //no lp  { value: '0x39fBBABf11738317a448031930706cd3e612e1B9', label: 'XRP' },
        { value: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599', label: 'BTC' },
        { value: '0x111111111117dc0aa78b770fa6a738034120c302', label: '1INCH' },
       { value: '0xdac17f958d2ee523a2206206994597c13d831ec7', label: 'USDT' },
        { value: '0x4Fabb145d64652a948d72533023f6E7A623C7C53', label: 'BUSD' },
     //   { value: '0x7083609fce4d1d8dc0c979aab8c869ea2c873402', label: 'DOT' },
     //   { value: '0x8ff795a6f4d97e7887c79bea79aba5cc76444adf', label: 'BCH' },
        { value: '0x514910771AF9Ca656af840dff83E8264EcF986CA', label: 'LINK' },
     //   { value: '0x4338665cbb7b2485a8855a139b75d5e34ab0db94', label: 'LTC' },
        { value: '0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0', label: 'POLYGON' },
     //   { value: '0x85eac5ac2f758618dfa09bdbe0cf174e7d574d5b', label: 'TRX' },
        { value: '0x7Fc66500c84A76Ad7e9c93437bFc5Ac33E2DDaE9', label: 'AAVE' },
      //noliquidity  { value: '0x8D983cb9388EaC77af0474fA441C4815500Cb7BB', label: 'ATOM' },
     //   { value: '0x16939ef78684453bfdfb47825f8a5f714f12623a', label: 'XTZ' },
        { value: '0xc00e94cb662c3520282e6f5717214004a7f26888', label: 'COMP' },
      //  { value: '0x0000000000000000000000000000000000000000', label: 'BNB' }
    ]
}
import React, { useState, lazy, useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { ethers } from 'ethers'
import pancakeswapRouter from './web3/pancakeswapRouter'
import jsonProviders from './web3/jsonProviders'
import TokenOptions from './web3/tokenOptions'

import Tokens from './web3/tokens'
import ChainIDs from './web3/chainIDs'
import ContractData from './web3/contractData'
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";

import AccessibleNavigationAnnouncer from './components/AccessibleNavigationAnnouncer';

let timer;

const Layout = lazy(() => import('./containers/Layout'))

const defaultChain = 'BNB';
const defaultSwapRouter = pancakeswapRouter.address[defaultChain];
const defaultProvider = new ethers.providers.JsonRpcProvider(jsonProviders[defaultChain]);
const defaultContractDecimals = 18;
const defaultContractAddress = ContractData.address[defaultChain];
const defaultContractABI = ContractData.abi;

const defaultContract = new ethers.Contract(defaultContractAddress, defaultContractABI, defaultProvider);

const pcsRouterContract = new ethers.Contract(defaultSwapRouter, pancakeswapRouter.abi, defaultProvider);

const getAmountsOut = async (quoteAmount, path) => {
  return await pcsRouterContract.functions['getAmountsOut'](
    quoteAmount,
    path,
    { gasLimit: 1000000000000 }
  )
}

const getContractTokenPrice = async (chain) => {
  let result;

  try{
    result = await getAmountsOut(`${1 * Math.pow(10, defaultContractDecimals)}`, [defaultContractAddress, Tokens[chain].WETH.address, Tokens[chain].STABLE.address])
  }catch(err){
    result = {amounts:[0,0,0]}
  }

  const usdPrice = Number(result.amounts[2].toString()) / Math.pow(10, Tokens[chain].STABLE.decimals)
  // console.log('Contract Token', usdPrice)

  return usdPrice
}

const getChainTokenPrice = async (chain) => {
  const result = await getAmountsOut(`${1 * Math.pow(10, Tokens[chain].WETH.decimals)}`, [Tokens[chain].WETH.address, Tokens[chain].STABLE.address]);
  const usdPrice = Number(result.amounts[1].toString()) / Math.pow(10, Tokens[chain].STABLE.decimals);
  // console.log('bnb', usdPrice);
  return usdPrice;
}

const getContractTokenVolume = async () => {
  const result = await fetch('https://api.coingecko.com/api/v3/simple/price?ids=tiki-token&vs_currencies=usd&include_market_cap=false&include_24hr_vol=true&include_24hr_change=false&include_last_updated_at=false');
  const resolved = await result.json();
  const volume = resolved['tiki-token'].usd_24h_vol;
  return volume;
}

function App() {

//const [wallet, setWallet] = useState(null)
  const [currentChain, setCurrentChain] = useState(defaultChain);
  const [contract, setContract] = useState(defaultContract);
  const [totalPaid, setTotalPaid] = useState(0)
  const [chainTokenHoldings, setChainTokenHoldings] = useState(0)
  const [chainTokenPrice, setChainTokenPrice] = useState(0)
  const [contractTokenPrice, setContractTokenPrice] = useState(0)
  const [contractTokenVolume, setContractTokenVolume] = useState(0)
  const [dividendToken, setDividendToken] = useState(null); 
  const [label, setLabel] = useState();
  const [defaultOption, setDefaultOption] = useState();
  const [providerAddress, setProviderAddress] = useState(null);

  const [highestBuyers, setHighestBuyers] = useState([])

  const [contractTokenHoldings, setContractTokenHoldings] = useState(0)
  const [paid, setPaid] = useState(0)
  const [lastPaid, setLastPaid] = useState(0)
  const [nextPayoutProgress, setNextPayoutProgress] = useState(0)
  const [nextPayoutValue, setNextPayoutValue] = useState(0)

  const [refreshAddressData, setRefreshAddressData] = useState(true)
  const [refreshTimeData, setRefreshTimeData] = useState(true)

  const [isModalOpen, setIsModalOpen] = useState(true)

  const [address, setAddress] = useState( localStorage.getItem('address') || '' )  


/*****************/

  const searchArrayIndex = async (valueKey, myArray) => {
    console.log('SEARCH', {myArray})
    try{
      for (var i=0; i < myArray.length; i++) {
          if (myArray[i].value.toUpperCase() === valueKey.toUpperCase()) {
              return i;
          }
      }
    }catch(e){
      console.log('Refresh page!')
    }
  }

  const openModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

/*****************/
  const getMetamaskWallet = async () => {
    let metamask;

    const providerOptions = {
      walletconnect: {
        package: WalletConnectProvider, // required
        options: {
         // rpc: {
          //  56: 'https://bsc-dataseed.binance.org/'
         // },
          //network: 'binance',
          //chainId: 56,
          infuraId: 'f6adf3f23ca149c8a2e5e0e4e32b617d',
        } 
      }
    }

    const web3Modal = new Web3Modal({
      theme: 'dark', // optional
      providerOptions, // required
      cacheProvider: true, // optional
    });

    try {
      console.log('tried connect')

      let provider = await web3Modal.connect();
      //metamask = new ethers.providers.Web3Provider(window.ethereum)
      metamask = new ethers.providers.Web3Provider(provider)
      console.log(metamask)
      let { chainId } = await metamask.getNetwork()
      if (ChainIDs[chainId]) setCurrentChain(ChainIDs[chainId]);
      // hidden to allow all chains
      /* if(chainId != 56){
        throw 'Wrong Chain!'
      } */

    } catch (e) {
      return null;
    }
    // Prompt user for account connections
    // await metamask.send("eth_requestAccounts", []);
    return metamask.getSigner();
    // metamask.getSigner().getAddress
  }
  
  const connectWallet = (chain) => {
    setAddress('')
    getMetamaskWallet().then((signer) => {

      if (signer === null){
        setDividendToken(null);
        return
      }

      signer.getAddress().then(async (address) => {

        console.log({a: ContractData.address[chain], d: ContractData.abi, signer})
        let tokenContract = new ethers.Contract(ContractData.address[chain], ContractData.abi, signer);

        let currentToken;
        
        try{
          currentToken = await tokenContract.getDividendTokenUser(address);
        }catch(e){
          currentToken = '0x0000000000000000000000000000000000000000';
        }

        setAddress(address);
        setProviderAddress(address);
        setContract(tokenContract);
        console.log('CONTRACT SET', tokenContract)
        setDividendToken(currentToken);

        try{
          setLabel(TokenOptions[currentChain][0]);
        }catch(err){
          console.log('could not set label', err)
        }

      });
    });
  }

  const callContract = () => {
    contract.getNumberOfDividendTokenHolders().then(holders => {
      contract.balanceOf(address).then(balance => {
        setContractTokenHoldings((balance / 1e18).toFixed(0));
        contract.getAccountDividendsInfo(address).then(result => {
          defaultProvider.getBalance(address).then(balance => {
            setChainTokenHoldings((balance/1e18).toFixed(4));
            setPaid( parseInt(result[4]._hex, 16) - parseInt(result[3]._hex, 16) );
            setLastPaid(parseInt(result[5]._hex, 16)*1000);
            setNextPayoutProgress((100-((parseInt(result[2]._hex, 16)/parseInt(holders._hex, 16))*100)).toFixed(0));
            setNextPayoutValue( (parseInt(result[3]._hex, 16)/1e18).toFixed(4) );
            window.clearTimeout(timer);
            timer = window.setTimeout(function(){ setRefreshAddressData(!refreshAddressData) }, 9000);
          })
        })
      })
    }).catch((e)=>{console.log('New contract')});
  }

/*****************/
  useEffect(() => {
    getContractTokenPrice(currentChain).then(setContractTokenPrice)
    getChainTokenPrice(currentChain).then(setChainTokenPrice)
    getContractTokenVolume().then(setContractTokenVolume)
  }, [])

  useEffect(() => {

    if (ethers.utils.isAddress(address)) {
      if (localStorage.getItem('address') !== address) localStorage.setItem('address', address);
      callContract(address);
    }

  }, [address, refreshAddressData])

  useEffect(() => {
    contract.getTotalDividendsDistributed().then(total => {
      setTotalPaid((total/1e18).toFixed(0))
      setTimeout(function(){ setRefreshTimeData(!refreshTimeData) }, 5000);
    }).catch((e)=>{console.log('New contract')});
  }, [contract, refreshTimeData])

  return (
    <>
      
      <Router>
        <AccessibleNavigationAnnouncer />
        <Switch>

          <Route path="/" render={(props) => (

            <Layout {...props} 
            paid={paid} 
            setPaid={setPaid} 
            lastPaid={lastPaid} 
            totalPaid={totalPaid} 
            setLastPaid={setLastPaid} 
            highestBuyers={highestBuyers} 
            nextPayoutValue={nextPayoutValue} 
            nextPayoutProgress={nextPayoutProgress} 
            setNextPayoutValue={setNextPayoutValue}
            setNextPayoutProgress={setNextPayoutProgress} 
            //
            label={label} 
            options={TokenOptions[currentChain] ? TokenOptions[currentChain] : TokenOptions[defaultChain]} 
            defaultOption={defaultOption} 
            currentChain={currentChain} 
            address={address} 
            setAddress={setAddress}
            setLabel={setLabel} 
            contract={contract} 
            setContract={setContract} 
            // 
            dividendToken={dividendToken} 
            setDividendToken={setDividendToken} 
            connectWallet={connectWallet} 
            chainTokenHoldings={chainTokenHoldings} 
            chainTokenPrice={chainTokenPrice} 
            contractTokenVolume={contractTokenVolume} 
            setContractTokenVolume={setContractTokenVolume} 
            contractTokenPrice={contractTokenPrice} 
            contractTokenHoldings={contractTokenHoldings} 
            setContractTokenHoldings={setContractTokenHoldings} 
            getMetamaskWallet={getMetamaskWallet} 
            providerAddress={providerAddress} 
            setProviderAddress={setProviderAddress} 
            />

          )} />

        </Switch>
      </Router>
    </>
  )

}

export default App
